/* overwrite styles for survey js */
.sv_main {
  overflow-y: auto;
}

.sv_main, .sv_main form {
  height: 100%;
  background: white;
  font-family: Roboto, Helvetica Neue, Arial, sans-serif;
}

.sv_main form {
  height: inherit;
}

.sv_main .sv_container {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.sv_main .sv_container .sv_body {
  padding: 0;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  border-top: none;
}

.sv_main .sv_container .sv_body .sv_p_root {
  flex: 1 0 auto;
}

.sv_main .sv_container .sv_body .sv_nav {
  padding: 1em;
}

.sv_main .sv_container .sv_body .sv_nav input[type="button"] {
  padding: .5em 0;
}

.sv_main .sv_container .sv_body .sv_progress {
  width: 50%;
  right: 24px;
  position: absolute;
  top: 2.5em;
}

.sv_main .sv_container .sv_body .sv_progress .sv_progress_bar {
  margin-top: 0;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_page_title {
  font-size: 1.2em;
  padding:  0.5em 1em;
  margin-top: 0;
}

.sv_default_css button  {
  border: 1px solid rgb(24, 131, 239);
  color: rgb(24, 131, 239) !important;
  background-color: transparent !important;
}

.sv_main .sv_container .sv_body .sv_p_root > .sv_row {
  padding: 1.5em 0;
}

.sv_main .sv_container .sv_body .sv_p_root > .sv_row:nth-child(2n) {
  background-color: transparent;
}

/* set disabled color for form field */
input[disabled] {
  border-color: transparent !important;
}

input[disabled] ~ span {
  border: 1px solid #e7e7e7 !important;
}

div[disabled] {
  border-color: transparent !important;
  padding-left: 0 !important;
}

textarea[readonly] {
  pointer-events: none;
  border: none;
  resize: none;
}

.sv_main span {
  word-break: normal !important;
}

/* Input fields set widths */
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_other input,  .sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_dropdown_control, .sv_main .sv_container .sv_body .sv_p_root .sv_q input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="image"]):not([type="checkbox"]):not([type="radio"]), .sv_main .sv_container .sv_body .sv_p_root .sv_q select  {width:30rem}
#root .sv_main .sv_container .sv_body .sv_p_root .sv_q input[type="date"] {width:23rem}
.sv_main .sv_container .sv_body .sv_p_root .sv_q textarea, .sv_main .sv_container .sv_body .sv_p_root .sv_q sv_q_matrix_dynamic .sv_q_text_root, .sv_main .sv_container .sv_body .sv_p_root .sv_q table input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="image"]):not([type="checkbox"]):not([type="radio"]):not([type="date"]) {width:50rem}

/* 100% back on when in dynamic table*/
.sv_main .sv_container .sv_body .sv_p_root .sv_q table input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="image"]):not([type="checkbox"]):not([type="radio"]):not([type="date"]), .sv_main .sv_container .sv_body .sv_p_root .sv_q table textarea, .sv_main .sv_container .sv_body .sv_p_root .sv_q table input[type="date"] {width:100%}

/* Hide the exposed drop down indicator revert to default browser version */ 
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper:before {display: none;}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper select {-webkit-appearance: menulist;}

/* Remove the border/ shrink the padding */
.sv_main .sv_container .sv_body .sv_p_root > .sv_row {padding: 0.5rem 0!important; border-bottom: none}
.sv_main .sv_container .sv_body .sv_p_root .sv_page_title, .sv_main .sv_container .sv_body .sv_p_root .sv_q {padding-left: 2rem;}
.sv_main .sv_container .sv_body .sv_p_root .sv_q {padding-bottom:0.5rem}

/* tidy the validation message up */
#root .sv_main .sv_q_erbox {border: none; background-color:transparent; color: #ed5565; padding:0; font-style: italic}
#root .sv_q_erbox ~ div select, #root .sv_q_erbox ~ div .sv_q_rating_item_text, #root .sv_q_erbox ~ input {border-color: #ed5565!important; color:#ed5565!important}

/* Fix wrapping issues with the progress bar ()  */
.sv_main .sv_container .sv_body .sv_progress {width: 30%;}
.sv_main .sv_container .sv_body .sv_progress .sv_progress_bar {min-width: 1px;}

/* stop the radio button labels from wrapping */
.sv_q_radiogroup_label {white-space: nowrap}

/* reduce the left padding on inputs */
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_other input, .sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_text_root, .sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_dropdown_control, .sv_main .sv_container .sv_body .sv_p_root .sv_q input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="image"]):not([type="checkbox"]):not([type="radio"]), .sv_main .sv_container .sv_body .sv_p_root .sv_q select, .sv_main .sv_container .sv_body .sv_p_root .sv_q textarea {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

@media screen and (max-width: 960px) {
  /* Input fields back to 100% for small screens */
  .sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_other input,  .sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_dropdown_control, .sv_main .sv_container .sv_body .sv_p_root .sv_q input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="image"]):not([type="checkbox"]):not([type="radio"]), .sv_main .sv_container .sv_body .sv_p_root .sv_q select  {width:100%}
  #root .sv_main .sv_container .sv_body .sv_p_root .sv_q input[type="date"] {width:100%}
  .sv_main .sv_container .sv_body .sv_p_root .sv_q textarea, .sv_main .sv_container .sv_body .sv_p_root .sv_q sv_q_matrix_dynamic .sv_q_text_root, .sv_main .sv_container .sv_body .sv_p_root .sv_q table input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="image"]):not([type="checkbox"]):not([type="radio"]):not([type="date"]) {width:100%}

  /* resize the padding / remove border on dynamic table */
  .sv_main .sv_container .sv_body .sv_p_root table td {padding:0.5rem 0}
  .sv_main table.sv_q_matrix tr, .sv_main table.sv_q_matrix_dropdown tr, .sv_main table.sv_q_matrix_dynamic tr {padding: 1rem 0; border:none}

  /* tidy up the progress bar */
  .sv_main .sv_container .sv_body .sv_progress  {position: absolute; top: 5.1rem; left:2rem; display: inline-block; white-space: nowrap;}
  .sv_main .sv_container .sv_body .sv_progress .sv_progress_bar > span {font-size: 1.2rem}
  .sv_main .sv_container .sv_body .sv_p_root .sv_page_title {font-size: 1.2em; padding: 0.5em 1em 0.5em 2rem; height: 60px;}
  .sv_main .sv_container .sv_body .sv_progress {height: 0.2em;}
}

/* make survey scroll both ways, make sure all content are shown */
.sv_main {
  position: relative;
}

.sv_main form {
  position: absolute;
  width: 100%;
}

/* make sure input inherit defined font family */
.sv_body input,
.sv_body textarea {
  font-family: inherit;
}