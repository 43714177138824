@import 'normalize.css';

* {
    outline: none !important;
}

html {
    font-size: 62.5%;
    font-family: Roboto, Helvetica Neue, Arial, sans-serif;
    background-color: #262933;
}

body {
    font-size: 14px;
    line-height: 1.4;
}

html, body, #root {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    padding: 0;
    margin: 0;
}

a[role=button] {
    text-decoration: none;
}

form label {
    z-index: 99;
}

.isDisableLink {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: black;
}

.flex-row-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.flex-row-container > * {
    flex: 0 0 auto;
}

.flex-row-container .enable-shrink {
    flex-shrink: 1;
}

.flex-row-container .enable-grow {
    flex-grow: 1;
}

.flex-row-container.with-gutter > *:not(:last-child) {
    margin-right: 8px;
}

.checkboxGroups {
    display: flex;
    flex-wrap: wrap;
}
.checkboxGroups > * {
    flex: 1 1 50%;
}

.checkboxGroups > * label {
    margin-right: 0 !important;
}

.with-vertical-divider {
    padding-left: 16px;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.with-horizontal-divider {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.items-container {
   width: 100%;
   display: flex;
   flex-direction: column;
}

.items-container > *:not(:first-child) {
    margin-top: 8px;
}

.items-container.sm > *:not(:first-child) {
    margin-top: 16px;
}

.items-container.md > *:not(:first-child) {
    margin-top: 24px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10/11 specific fix for advance filter overlap*/
    .advanced-filter-sections {
        flex-shrink: 0;
    }
}

.MuiSnackbarContent-message {
  flex: 1 1 auto;
}

.dialog-content table {
    width: 100%;
}

/* remove clear button on IE */
::-ms-clear {
  display: none;
}

/* remove number input arrows -> which covers input and not that useful */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.break-word {
  word-break: break-word;
}

@import 'tailwind.css';
